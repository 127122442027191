import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import locationsData from '../data/locations.json';
import { BsWhatsapp } from 'react-icons/bs';
import ScrollToTopButton from '../components/ScrollToTopButton';

const LocationPage = () => {
    const { location } = useParams();
    const canonicalUrl = `https://online-spoken-english.sivasoft.in/${location}`;
    // If there is no location, show a message with a link instead of the rest of the page
    if (!location) {
        return (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <h2>Visit <a href="https://sivasoft.in">https://sivasoft.in</a> for more details</h2>
            </div>
        );
    }

    // Find the location data matching the slug
    const locationData = locationsData.find(
        (loc) =>
            loc.slug.training === location ||
            loc.slug.course === location ||
            loc.slug.classes === location
    );

    // Return a not found message if location data is missing for a specific URL
    if (!locationData) return <div>Page not found</div>;

    // Determine the meta title, description, and keywords based on the slug
    let title = locationData.title.training;
    let description = locationData.description.training;
    let keywords = locationData.keywords.training;
    let type = locationData.type.training;

    // Update meta fields based on the specific URL parameter (location)
    if (locationData.slug.course === location) {
        title = locationData.title.course;
        description = locationData.description.course;
        keywords = locationData.keywords.course;
        type = locationData.type.course;
    } else if (locationData.slug.classes === location) {
        title = locationData.title.classes;
        description = locationData.description.classes;
        keywords = locationData.keywords.classes;
        type = locationData.type.classes;
    }
    // JSON-LD structured data object with dynamic title and URL
    const schemaData = {
        "@context": "http://schema.org",
        "@type": "Product",
        "name": title,
        "aggregaterating": {
            "@type": "AggregateRating",
            "ratingCount": 219560,
            "bestRating": "5",
            "ratingValue": "4.98",
            "worstRating": "0"
        },
        "url": canonicalUrl
    };
    return (
        <div>
            <Helmet>
                <link rel="canonical" href={canonicalUrl} />
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
                <script type="application/ld+json">
                    {JSON.stringify(schemaData)}
                </script>
            </Helmet>

            <div className="header-top">
                <span className='text-white'> 🎉 12th Anniversary Offer! Get 50% off all courses 🎉
                </span>
            </div>
            {/* Header */}
            <header className="bg-danger text-white py-3">
                <div className="container">
                    <div className='row'>
                        <div className='col-lg-8'>
                            <h2>Siva Soft Online {type} - {locationData.city}</h2>
                            <h6 className="lead">{title}</h6>
                        </div>
                        <div className='col-lg-4 text-center'>
                            <img src="/images/p-3.png" width={200} height={100} alt={keywords} />
                        </div>
                    </div>

                </div>
            </header>
            <div className="mt-4 mb-4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4">
                            <div className="logo float-lg-left text-center">
                                <a href="https://sivasoft.in"
                                    rel="noopener noreferrer">
                                    <img src="/images/sivasoft-logo.png"
                                        alt={keywords}
                                        className="sivasoft-logo" width={40} height={40} />
                                    <span className="sivasoft-text ms-2">Siva Soft</span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="text-center">
                                <div className="cont">
                                    <p> Need Help ? call us free </p> <span> <i className="bi bi-whatsapp"> </i> +91 63029 64834</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4  mt-20 mt-lg-0">
                            <div className="button float-lg-right text-center">
                                <a href="https://forms.gle/ygt3scEY32hvnrxz6"
                                    target="_blank" rel="noopener noreferrer"
                                    className="main-btn">Apply For Course </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            { /* Navbar */}
            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container">
                    <a className="navbar-brand" href="https://sivasoft.in">

                        Spoken English {type}
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item">
                                <a className="nav-link active" href="https://sivasoft.in">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://sivasoft.in/classroom-training-courses-list.html">Classroom</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link"
                                    href="https://sivasoft.in/online-training-courses-list.html">Online</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link"
                                    href="https://sivasoft.in/sivasoft-batch-timings.html">Timings</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link"
                                    href="https://forms.gle/ygt3scEY32hvnrxz6"
                                    target="_blank" rel="noopener noreferrer">Apply For Course</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link"
                                    href="https://forms.gle/7Ame823xF4VK1Xm87"
                                    target="_blank" rel="noopener noreferrer">Apply For Faculty</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link"
                                    href="https://sivasoft.in/contact.html">Contact</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <hr />
            <div className='container'>
                <h6 className="mt-1 mt-2 pt-3">NOTE: Our First Priority is to provide customized Timings
                    &
                    Duration as per Student Requirement</h6>

                <a href="https://sivasoft.in/sivasoft-batch-timings.html">Click here for batch timings</a>
                <div className="row mt-3 pt-3 px-3">
                    <div className="col-md-12">
                        <div className="mt-3">
                            <hr />
                            <div className="button text-center">
                                <a href="https://forms.gle/ygt3scEY32hvnrxz6" target="_blank" rel="noopener noreferrer"
                                    className="main-btn">Apply
                                    For Course</a>
                            </div>
                            <hr />
                            <a
                                href="https://sivasoft.in/online-spoken-english-training-course.html">🚀 ONLINE TRAINING COURSE: Master Spoken English Training Course</a>
                            <hr />
                            <p>Group Training Fee: <s style={{ color: "red" }}>₹8,000/-</s> ➡️ Offer: <span
                                style={{ color: "green" }}>₹4,000/-</span></p>
                            <p>One-On-One Training Fee: <s style={{ color: "green" }}>₹16,000/-</s> ➡️ Offer: <span
                                style={{ color: "green" }}>₹8,000/-</span></p>
                            <p class="mb-3">Duration: 2 Months</p>
                            <div class="row mt-3 mb-3">

                                <div class="col-md-6">
                                    <p>🎓 Eligibility: Any One</p>
                                    <p>👨‍🏫 Trainer: Mrs.Vandana (10+Years of
                                        Experience: Completed 100+batches successfully)</p>
                                    <p>🌟 Offer: Valid only for 7 days</p>

                                    <p>🌐 Website: https://www.sivasoft.in</p>


                                </div>
                                <div class="col-md-6">


                                    <p>🎓 Free Course Completion Certificate</p>

                                    <p>
                                        <strong>📚 Live Classes + Lifetime: Video Access</strong>
                                    </p>
                                    <p>✨ One-on-One Demo Session</p>
                                </div>
                            </div>
                            <h4 class="mb-2"> Course Curriculum </h4>
                            <h4 class="mt-2">Talk Tactics</h4>
                            <h6 class="mt-2">Introduction of English.</h6>
                            <ul class="left">
                                <li>What is called word?</li>
                                <li>How many word are required to speak in English?</li>
                                <li>What is called Sentence?</li>
                                <li>How to form a Sentence?</li>
                            </ul>
                            <h4 class="mt-2">Importance of English in this competitive world.</h4>
                            <h6 class="mt-2">English family: </h6>
                            <ul class="left">
                                <li>Nouns</li>
                                <li>Pronouns</li>
                                <li>Verbs</li>
                                <li>Adjectives</li>
                                <li>Adverbs</li>
                                <li>Prepositions</li>
                                <li>Conjunctions</li>
                                <li>Interjections.</li>
                            </ul>
                            <h4 class="mt-2">Every day actions (routine actions, regular actions, common actions)</h4>
                            <h4 class="mt-2">Present actions & practice (DO/DOES)</h4>
                            <h4 class="mt-2">Past actions & Practice (DID).</h4>
                            <h4 class="mt-2">Future actions & Practice(SHALL/WILL)</h4>
                            <h4 class="mt-2">Total Practice(DO/DOES, DID, SHALL/WILL)</h4>
                            <h6 class="mt-2">Going on actions :</h6>
                            <ul class="left">
                                <li>Present (am/is/are)</li>
                                <li>Past (was/were)</li>
                                <li>Future (shall be/will be)</li>
                            </ul>
                            <h6 class="mt-2">Special verbs :</h6>
                            <ul class="left">
                                <li>Can---Could</li>
                                <li>May---Might</li>
                                <li>Should---Must </li>
                                <li>Would</li>
                            </ul>
                            <h6 class="mt-2">Rewind Reel-1</h6>
                            <ul class="left">
                                <li>Total practice</li>
                                <li>Preparation for Oral test</li>
                                <li>Oral test(Exam-1)</li>
                            </ul>
                            <h4 class="mt-2">Advance course</h4>
                            <h6 class="mt-2">Articles :</h6>
                            <ul class="left">
                                <li>Definite Article (The) </li>
                                <li>Indefinite Articles (A, An)</li>
                            </ul>
                            <h6 class="mt-2">Prepositions:</h6>
                            <ul class="left">
                                <li>Types of prepositions.</li>
                                <li>Difference between(beside,besides/in,into/between, among/up, upon, etc )</li>
                            </ul>
                            <h6 class="mt-2">‘Have’ forms:</h6>
                            <ul class="left">
                                <li>Have in present (have/has)</li>
                                <li>Have in past (had)</li>
                                <li>Have in future (shall have/will have)</li>
                            </ul>
                            <h6 class="mt-2">‘Have’ forms with special verbs :</h6>
                            <ul class="left">
                                <li>Should have----must have</li>
                                <li>May have----Might have</li>
                                <li>Would have----Could have</li>
                            </ul>
                            <h6 class="mt-2">‘Have’ with ‘Been’</h6>
                            <ul class="left">
                                <li>Have been----Has been</li>
                                <li>Had been</li>
                                <li>Shall have been----will have been</li>
                                <li>Should have been----must have been</li>
                                <li>May have been----might have been</li>
                                <li>Would have been----could have been</li>
                            </ul>
                            <h6 class="mt-2">Rewind reel-2.</h6>
                            <ul class="left">
                                <li>Total practice.</li>
                                <li>Preparation for Oral test.</li>
                                <li>Oral test(Exam-2)</li>
                            </ul>
                            <h6 class="mt-2">Question tags.</h6>
                            <h6 class="mt-2">If conditions:</h6>
                            <ul class="left">
                                <li>Real conditions or possible conditions.</li>
                                <li>Unreal conditions or Imaginary conditions or impossible conditions</li>
                                <li>(if I were, if I had been, etc.)</li>
                            </ul>
                            <h6 class="mt-2">‘To’ infinitives(part 1)</h6>
                            <ul class="left">
                                <li>Have to---Has to </li>
                                <li>Need to---Needs to</li>
                                <li>Dare to---Dares to, etc.</li>
                            </ul>
                            <h6 class="mt-2">‘To’ infinitives(part 2)</h6>
                            <ul class="left">
                                <li>Able to </li>
                                <li>Willing to </li>
                                <li>Going to, etc.</li>
                            </ul>
                            <h6 class="mt-2">Rewind reel-3</h6>
                            <ul class="left">
                                <li>Total practice.</li>
                                <li>Preparation for Oral test.</li>
                                <li>Oral test-3</li>
                            </ul>
                            <h6 class="mt-2">Direct speech & Indirect speech.</h6>
                            <h6 class="mt-2">Introduction of Voice</h6>
                            <ul class="left">
                                <li>What is called Voice?</li>
                                <li>How many types of Voice?</li>
                                <li>Difference between Active voice & Passive voice</li>
                                <li>Voice 1 & practice.</li>
                                <li>Voice 2 & practice.</li>
                                <li>Voice 3 & practice.</li>
                            </ul>
                            <h6 class="mt-2">Total practice.</h6>
                            <h6 class="mt-2">‘Be’forms-1</h6>
                            <h6 class="mt-2">‘Be‘forms-2</h6>
                            <h6 class="mt-2">Classified Vocabulary </h6>
                            <h6 class="mt-2">Spelling:</h6>
                            <ul class="left">
                                <li>Spelling rules</li>
                                <li>Tips to Remember.</li>
                                <li>Silent letters</li>
                            </ul>
                            <h6 class="mt-2">Idioms</h6>
                            <h6 class="mt-2">Phrasal verbs</h6>
                            <h6 class="mt-2">Root words</h6>
                            <h6 class="mt-2">One word substitutions</h6>
                            <h6 class="mt-2">Double words.</h6>
                            <h6 class="mt-2">Synonyms & Antonyms.</h6>
                            <h6 class="mt-2">Homonyms.</h6>
                            <h4 class="mt-2">Skill Development PROGRAM</h4>
                            <h6 class="mt-2">FIRST IMPRESSION:</h6>
                            <ul class="left">
                                <li>Body Language</li>
                                <li>Handshakes</li>
                                <li>Smile</li>
                                <li>Facial Expressions, etc..</li>
                            </ul>
                            <h6 class="mt-2">Effective Public Speaking:</h6>
                            <ul class="left">
                                <li>Conquer of stage fear</li>
                                <li>Speaking on the dais</li>
                                <li>Holding of mike</li>
                                <li>Voice Modulations</li>
                                <li>Gestures</li>
                                <li>Eye contact</li>
                            </ul>
                            <h6 class="mt-2">Group Discussions</h6>
                            <ul class="left">
                                <li>Types of Group Discussions.</li>
                                <li>Rules of GD.</li>
                                <li>Do’s & Dont’s in GD.</li>
                            </ul>
                            <h6 class="mt-2">Presentation Skills</h6>
                            <ul class="left">
                                <li>Content preparation.</li>
                                <li>Starting & Closing of any presentation</li>
                                <li>Giving Conclusion</li>
                            </ul>
                            <h6 class="mt-2">Conversations.</h6>
                            <ul class="left">
                                <li>Silly conversation </li>
                                <li>Emotional conversations</li>
                                <li>Situational conversations</li>
                                <li>Formal conversations</li>
                            </ul>
                            <h6 class="mt-2">Debates</h6>
                            <h6 class="mt-2">Role plays</h6>
                            <h6 class="mt-2">Selling Skills.</h6>
                            <h6 class="mt-2">Dramatization.</h6>
                            <h6 class="mt-2">JAM Topics (just a minute).</h6>
                            <h6 class="mt-2">Mock Interviews.</h6>
                        </div>
                    </div>
                </div>
            </div>
            { /* Footer */}
            <footer id="footer-part">
                <div className="footer-top pt-40 pb-70">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="footer-about mt-40">
                                    <div className="logo">
                                        <a href="index.html">
                                            <img src="/images/sivasoft-logo.png"
                                                alt={keywords}
                                                className="sivasoft-logo" width={40} height={40} />
                                            <span className="sivasoft-footer-text"> Siva Soft </span>

                                        </a> </div> <p> SIVASOFT TECHNOLOGIES PRIVATE LIMITED (Training &
                                            Development) is an ISO 9001: 2015 certified company: No 1 Training Company with Dedicated Trainers and Professionals from Top MNCs like Amazon, Google, Microsoft, Infosys... </p>

                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="footer-address mt-40">
                                    <div className="footer-title pb-25">
                                        <h6> Contact Us </h6>
                                    </div>
                                    <ul>
                                        <li>
                                            <div className="icon">
                                                <i className="bi bi-geo-alt"> </i> </div>
                                            <div className="cont">
                                                <p> #417, Annapurna Block, Aditya Enclave, Near Ameerpet Metro, Hyderabad, India </p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <i className="bi bi-whatsapp"> </i> </div>
                                            <div className="cont">
                                                <p> +91 63029 64834 </p>
                                                <p> +91 92481 53330 </p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <i className="bi bi-envelope"> </i>
                                            </div> <div className="cont">
                                                <p> sivasoft @sivasoft.in </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-copyright pt-10 pb-25">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="copyright text-md-left text-center pt-15">
                                    <p> &copy; 2012 - {new Date().getFullYear()} SIVASOFT TECHNOLOGIES PRIVATE LIMITED
                                        (Training & Development) is an ISO 9001: 2015 certified company.All Rights Reserved.Logo, Images, and Content are the trademark of their respective owners. {title} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* WhatsApp Floating Button */}
            <a
                href="https://wa.me/916302964834"
                target="_blank" rel="noopener noreferrer"
                className="whatsapp-btn btn btn-success d-flex align-items-center justify-content-center"
                style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 1000 }}
            >
                <BsWhatsapp size={24} color="white" />
            </a>
            {/* Include the Scroll to Top Button */}
            <ScrollToTopButton />
        </div>
    );
};

export default LocationPage;
